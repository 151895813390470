/* Navigation Styles*/

.header--absolute {
    position: absolute;
    z-index: 999;
    top: 10px;
    right: 0;
    left: 0;

    &.stick {
        position: fixed;
        top: 0;

        padding-top: 20px !important;
        padding-bottom: 20px !important;

        animation: 700ms ease-in-out;
        animation-name: fadeInDown;

        background-color: #000;
    }
}

.logo {
    padding: 0;

    text-align: left;
}

.mainmenu {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;

    list-style: outside none none;
    li {
        line-height: 42px;

        margin: 0 21px;
        a {
            font-family: Source Sans Pro;
            font-size: 16px;
            font-weight: 600;

            text-transform: uppercase;

            color: #FFF;
        }
    }
}

.dg__header {
    .accounts {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        margin: 0;
        padding: 0;

        list-style: outside none none;
        li {
            a {
                font-family: Source Sans Pro;
                font-size: 14px;
                font-weight: 600;

                text-transform: uppercase;

                color: #FFFFFF;
            }
            &.active {
                a {
                    line-height: 47px;

                    display: inline-block;

                    height: 50px;
                    padding: 0 42px;

                    transition: all 0.5s ease 0s;

                    border: 1px solid #FFFFFF;
                    border-radius: 4px;
                    &:hover {
                        color: #F7A901;
                        background: #FFF none repeat scroll 0 0;
                    }
                }
            }
            & + li {
                margin-left: 30px;
            }
        }
    }
}

.dg__header.space-right-left {
    padding: 0 217px;
}

.mainmenu {
    li {
        &.drop {
            position: relative;
            ul {
                &.dropdown {
                    position: absolute;
                    top: 107%;
                    left: 0;

                    visibility: hidden;

                    width: 205px;
                    padding: 12px 0;

                    transition: all 0.5s ease 0s;

                    opacity: 0;
                    background: #FFF none repeat scroll 0 0;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                    li {
                        line-height: 29px;

                        margin: 0;
                        padding: 0 14px;
                        a {
                            font-weight: 600;
                            line-height: 29px;

                            transition: all 0.3s ease 0s;
                            text-transform: capitalize;

                            color: #444;
                            &:hover {
                                padding-left: 3px;

                                color: #1752B4;
                            }
                        }
                    }
                }
            }
            &:hover ul.dropdown {
                top: 100%;

                visibility: visible;

                opacity: 1;
            }
        }
    }
}

/* Responsive Css*/

@media #{$lg-layout} {
    .dg__header.space-right-left {
        padding: 0 10px;
    }
    .mainmenu li {
        margin: 0 14px;
    }
    .logo a img {
        width: 100%;
    }
    .dg__header .accounts li + li {
        margin-left: 11px;
    }
    .dg__header .accounts li.active a {
        padding: 0 14px;
    }
}

@media #{$md-layout} {
    .dg__header.space-right-left {
        padding-right: 30px;
        padding-left: 30px;
    }
    .header--absolute .container-fluid {
        position: relative;
        z-index: 99;
    }
    .mean-container {
        position: absolute;
        top: -9px;
        right: 24px;

        width: 93%;
    }
    .mean-container a.meanmenu-reveal {
        padding: 0;
    }

    .header--absolute {
        position: static;

        padding: 20px 15px !important;

        background-color: #000000;
    }
}

@media #{$sm-layout} {
    .dg__header.space-right-left {
        padding: 0 30px 0 30px;
    }
    .header--absolute .container-fluid {
        position: relative;
        z-index: 99;
    }
    .mean-container {
        position: absolute;
        top: -9px;
        right: 24px;

        width: 93%;
    }
    .mean-container a.meanmenu-reveal {
        padding: 0;
    }
    .header--absolute {
        position: static;

        padding: 20px 15px !important;

        background-color: #000000;
    }
}

@media #{$xs-layout} {
    .dg__header .accounts li.active a {
        padding: 0 36px;
    }
    .mean-container {
        top: 48px;

        width: 81%;
    }
    .dg__header .accounts {
        justify-content: flex-start;
    }
    .dg__header .accounts li.active a {
        line-height: 37px;

        height: 41px;
    }
    .header--absolute {
        position: static;

        padding: 20px 0 !important;

        background-color: #000000;
    }
}

@media #{$xxs-layout} {
    .dg__header .accounts li.active a {
        padding: 0 36px;
    }
    .mean-container {
        top: 48px;

        width: 81%;
    }
    .dg__header .accounts {
        justify-content: flex-start;
    }
}

@media #{$xlg-layout} {
    .dg__header.space-right-left {
        padding: 0 14px;
    }
    .dg__header .accounts li.active a {
        padding: 0 24px;
    }
    .dg__header .accounts li + li {
        margin-left: 18px;
    }
}

@media #{$xx-layout} {
    .dg__header.space-right-left {
        padding: 0 80px;
    }
}